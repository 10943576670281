import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Operation } from 'rfc6902';
import {
  IssueTypes,
  ShiftDetails,
  ShiftPullOut,
} from '@client-portal/models/shift';
import { ClpEnvironment } from '@client-portal/environment';
import { TimeValue } from '@client-portal/models/common';
import { ShiftHttpPaths } from './shift-http.paths';

@Injectable({ providedIn: 'root' })
export class ShiftHttpService {
  public issueTypes$ = this._httpClient.get<Array<IssueTypes>>(
    `${this._env.apiEndpoint}${ShiftHttpPaths.issueTypes}`,
  );

  constructor(
    private _httpClient: HttpClient,
    private _env: ClpEnvironment,
  ) {}

  public getShiftPullout$(shiftId: number): Observable<Array<ShiftPullOut>> {
    return this._httpClient.get<Array<ShiftPullOut>>(
      `${this._env.apiEndpoint}${ShiftHttpPaths.pulloutShift(String(shiftId))}`,
    );
  }

  public getShift$(shiftId: number): Observable<ShiftDetails> {
    return this._httpClient.get<ShiftDetails>(
      `${this._env.apiEndpoint}${ShiftHttpPaths.shift(String(shiftId))}`,
    );
  }

  public cancelOrder$(
    shiftId: number,
    reason: string,
  ): Observable<HttpResponse<void>> {
    return this._httpClient.put<void>(
      this._env.apiEndpoint + ShiftHttpPaths.cancelShift(shiftId),
      { reason },
      {
        observe: 'response',
      },
    );
  }

  public updateShift$(
    shiftId: number,
    data: Array<Operation>,
  ): Observable<HttpResponse<void>> {
    return this._httpClient.patch<void>(
      this._env.apiEndpoint + ShiftHttpPaths.shift(String(shiftId)),
      data,
      {
        observe: 'response',
      },
    );
  }

  public submitNotArrived$(shiftId: number): Observable<HttpResponse<void>> {
    return this._httpClient.put<void>(
      `${this._env.apiEndpoint}${ShiftHttpPaths.workerLate(shiftId)}`,
      {},
      {
        observe: 'response',
      },
    );
  }

  public confirmArrived$(
    shiftId: number,
    arrivalTime: TimeValue,
  ): Observable<HttpResponse<void>> {
    return this._httpClient.put<void>(
      `${this._env.apiEndpoint}${ShiftHttpPaths.workerArrived(shiftId)}`,
      { arrivalTime },
      {
        observe: 'response',
      },
    );
  }
}
