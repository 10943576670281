import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  Ward,
  WardDetails,
  WardFormEntry,
  WardList,
} from '@client-portal/models/ward';
import { AdvanceParam, PageParams } from '@client-portal/models/common';
import { ClpEnvironment } from '@client-portal/environment';
import { WardsHttpPaths } from './wards-http.paths';

@Injectable({
  providedIn: 'root',
})
export class WardsHttpService {
  constructor(
    private _httpClient: HttpClient,
    private _env: ClpEnvironment,
  ) {}

  public getWardTypes$(params?: AdvanceParam): Observable<Array<string>> {
    return this._httpClient.get<Array<string>>(
      `${this._env.apiEndpoint}${WardsHttpPaths.WardTypes}`,
      { params: { ...params } },
    );
  }

  public getClientWardDetails$(
    clientCode: string,
    wardCode: string,
  ): Observable<WardDetails> {
    return this._httpClient.get<WardDetails>(
      `${this._env.apiEndpoint}${WardsHttpPaths.ClientWardDetails.replace(
        '{clientCode}',
        clientCode,
      ).replace('{wardCode}', wardCode as string)}`,
    );
  }

  public getWardNames$(
    clientCode: string,
    params?: AdvanceParam,
  ): Observable<Array<Ward>> {
    return this._httpClient.get<Array<Ward>>(
      `${this._env.apiEndpoint}${WardsHttpPaths.WardNames.replace(
        '{clientCode}',
        clientCode,
      )}`,
      { params: { ...params } },
    );
  }

  public getWards$(
    clientCode: string,
    options: Partial<PageParams>,
  ): Observable<WardList> {
    return this._httpClient.get<WardList>(
      `${this._env.apiEndpoint}${WardsHttpPaths.Wards.replace(
        '{clientCode}',
        clientCode,
      )}`,
      { params: options },
    );
  }

  public addWard$(
    clientCode: string,
    wardEntry: WardFormEntry,
  ): Observable<HttpResponse<void>> {
    return this._httpClient.post<void>(
      this._env.apiEndpoint +
        WardsHttpPaths.Wards.replace('{clientCode}', clientCode),
      wardEntry,
      {
        observe: 'response',
      },
    );
  }
}
