import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  PreviousShifts,
  PreviousShiftsParams,
  RecentShiftsParams,
} from '@client-portal/models/orders';
import { ClpEnvironment } from '@client-portal/environment';
import { NewOrdersHttpPaths } from './new-orders-list-http.paths';

@Injectable({
  providedIn: 'root',
})
export class NewOrdersListHttpService {
  constructor(
    private _httpClient: HttpClient,
    private _env: ClpEnvironment,
  ) {}

  public getRecentShifts$(
    params?: RecentShiftsParams,
  ): Observable<PreviousShifts> {
    return this._httpClient.get<PreviousShifts>(
      `${this._env.apiEndpoint}${NewOrdersHttpPaths.recentShifts}`,
      {
        params: {
          ...params,
        },
      },
    );
  }

  public getPreviousShifts$(
    clientCode: string,
    params?: PreviousShiftsParams,
  ): Observable<PreviousShifts> {
    return this._httpClient.get<PreviousShifts>(
      `${this._env.apiEndpoint}${NewOrdersHttpPaths.previousShifts(clientCode)}`,
      {
        params: {
          ...params,
        },
      },
    );
  }
}
