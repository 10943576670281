import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClpEnvironment } from '@client-portal/environment';
import { ProfanityHttpPaths } from './profanity-http.paths';

@Injectable({
  providedIn: 'root',
})
export class ProfanityHttpService {
  public getProfanityRules$: Observable<Array<string>> = this._httpClient.get<
    Array<string>
  >(`${this._env.apiEndpoint}${ProfanityHttpPaths.Profanity}`);

  constructor(
    private _httpClient: HttpClient,
    private _env: ClpEnvironment,
  ) {}
}
