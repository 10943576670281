import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  UserProfile,
  UsersListWithFirstLastName,
  UserSubmitForm,
} from '@client-portal/models/user';
import { CodePageParams } from '@client-portal/models/common';
import { ClpEnvironment } from '@client-portal/environment';
import { UserHttpPaths } from './user-http.paths';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  public getUserDetails$ = this._httpClient.get<UserProfile>(
    `${this._env.apiEndpoint}${UserHttpPaths.userDetails}`,
  );

  constructor(
    private _httpClient: HttpClient,
    private _env: ClpEnvironment,
  ) {}

  public getUsers$(
    options: CodePageParams,
  ): Observable<UsersListWithFirstLastName> {
    return this._httpClient.get<UsersListWithFirstLastName>(
      `${this._env.apiEndpoint}${UserHttpPaths.users}`,
      { params: options },
    );
  }

  public submitNewUser$(data: UserSubmitForm): Observable<HttpResponse<void>> {
    return this._httpClient.post<void>(
      `${this._env.apiEndpoint}${UserHttpPaths.users}`,
      data,
      {
        observe: 'response',
      },
    );
  }

  public setActive$(): Observable<void> {
    return this._httpClient.put<void>(
      `${this._env.apiEndpoint}${UserHttpPaths.setActive}`,
      null,
    );
  }

  public resendVerificationEmail$(): Observable<void> {
    return this._httpClient.post<void>(
      `${this._env.apiEndpoint}${UserHttpPaths.resendEmailVerification}`,
      null,
    );
  }
}
