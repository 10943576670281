import { computed, Injectable, signal } from '@angular/core';
import {
  catchError,
  filter,
  map,
  Observable,
  of,
  OperatorFunction,
  shareReplay,
  switchMap,
} from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { CodePageParams } from '@client-portal/models/common';
import {
  UserItemWithFirstLastName,
  UserProfile,
  UsersListWithFirstLastName,
  UserSubmitForm,
} from '@client-portal/models/user';
import { UserHttpService } from '@client-portal/services/http';
import { AcaciumDeviceEnum } from '@client-portal/enums/common';
import { UserStatus } from '@client-portal/enums/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _emailVerified = signal(false);
  private _userStatusActive = signal(false);

  public userProfile$: Observable<UserProfile> =
    this._userHttpService.getUserDetails$.pipe(
      this._resolvePendingAccountStatus(this._userHttpService.setActive$()),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );

  public isAccountActive = computed(
    () => this._emailVerified() && this._userStatusActive(),
  );

  constructor(private _userHttpService: UserHttpService) {}

  public setEmailVerified(value: boolean): void {
    this._emailVerified.set(value);
  }

  public setUserStatusActive(value: boolean): void {
    this._userStatusActive.set(value);
  }

  public getUsersForTable$(
    options: CodePageParams,
    device: AcaciumDeviceEnum,
  ): Observable<UsersListWithFirstLastName> {
    const pageSize = device === AcaciumDeviceEnum.mobile ? 5 : 10;
    const userParams: CodePageParams = {
      ...options,
      pageSize,
    };
    return this._userHttpService.getUsers$(userParams).pipe(
      map((usersList) => ({
        ...usersList,
        items: usersList.items.map((user: UserItemWithFirstLastName) => ({
          ...user,
          name: `${user.firstName} ${user.lastName}`,
        })),
      })),
    );
  }

  public submitNewUser$(data: UserSubmitForm): Observable<HttpResponse<void>> {
    return this._userHttpService
      .submitNewUser$(data)
      .pipe(filter(({ status }) => status === 200));
  }

  public resendVerificationEmail$(): Observable<void> {
    return this._userHttpService.resendVerificationEmail$();
  }

  private _resolvePendingAccountStatus(
    setActive$: Observable<void>,
  ): OperatorFunction<UserProfile, UserProfile> {
    return (source$: Observable<UserProfile>) =>
      source$.pipe(
        switchMap((profile) => {
          if (profile.status === UserStatus.Pending) {
            return setActive$.pipe(
              map(() => ({
                ...profile,
                status: UserStatus.Active,
              })),
              catchError(() => of(profile)),
            );
          } else {
            return of(profile);
          }
        }),
      );
  }
}
