import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { OrderParams, Orders } from '@client-portal/models/orders';
import { OrderDetailSubmitForm } from '@client-portal/models/shift';
import { ClpEnvironment } from '@client-portal/environment';
import { OrdersHttpPaths } from './orders-http.paths';

@Injectable({
  providedIn: 'root',
})
export class OrdersHttpService {
  constructor(
    private _httpClient: HttpClient,
    private _env: ClpEnvironment,
  ) {}

  public getOrders$(options?: OrderParams): Observable<Orders> {
    if (options?.users) {
      delete options.users;
    }
    return this._httpClient
      .get<Orders>(`${this._env.apiEndpoint}${OrdersHttpPaths.Orders}`, {
        params: options,
      })
      .pipe(
        switchMap((response) =>
          of({
            ...response,
            availableFilters: {
              ...response.availableFilters,
              users: ['All', 'Added by me'],
            },
          }),
        ),
      );
  }

  public submitNewOrder$(
    data: OrderDetailSubmitForm,
  ): Observable<HttpResponse<void>> {
    return this._httpClient.post<void>(
      `${this._env.apiEndpoint}${OrdersHttpPaths.Orders}`,
      data,
      {
        observe: 'response',
      },
    );
  }
}
