import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { ClpEnvironment } from '@client-portal/environment';
import {
  ChecklistUrl,
  ComplaintIncidentDetails,
  WorkerDetailsHttpModel,
  WorkerHttpModel,
  WorkerShiftHistoryEntry,
} from '@client-portal/models/worker';
import { WorkerHttpPaths } from './worker-http.paths';

@Injectable({
  providedIn: 'root',
})
export class WorkerHttpService {
  constructor(
    private _httpClient: HttpClient,
    private _env: ClpEnvironment,
  ) {}

  public getWorker$(staffId: number): Observable<WorkerHttpModel> {
    return this._httpClient.get<WorkerHttpModel>(
      `${this._env.apiEndpoint}${WorkerHttpPaths.Worker(String(staffId))}`,
    );
  }

  public getWorkerDetails$(
    staffId: number,
  ): Observable<WorkerDetailsHttpModel> {
    return this._httpClient.get<WorkerDetailsHttpModel>(
      `${this._env.apiEndpoint}${WorkerHttpPaths.WorkerDetails(
        String(staffId),
      )}`,
    );
  }

  public getWorkerShiftHistory$(
    staffId: number,
    workedCount?: number,
  ): Observable<Array<WorkerShiftHistoryEntry>> {
    const params: { top: number } | undefined = workedCount
      ? { top: workedCount }
      : undefined;
    return this._httpClient.get<Array<WorkerShiftHistoryEntry>>(
      `${
        this._env.apiEndpoint
      }${WorkerHttpPaths.WorkerHistoryShifts(String(staffId))}`,
      { params },
    );
  }

  public submitComplaintsIncidents$(
    data: ComplaintIncidentDetails,
  ): Observable<HttpResponse<void>> {
    return this._httpClient.post<void>(
      `${this._env.apiEndpoint}${WorkerHttpPaths.Complaints}`,
      data,
      {
        observe: 'response',
      },
    );
  }

  public getWorkerChecklist$(
    staffId: string,
    shiftId: string,
  ): Observable<string | null> {
    return this._httpClient
      .get<ChecklistUrl>(
        this._env.apiEndpoint + WorkerHttpPaths.checklist(staffId, shiftId),
      )
      .pipe(
        catchError((e) => of({ url: null })),
        map((res) => res.url),
      );
  }
}
