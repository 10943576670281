import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Operation } from 'rfc6902';
import { CodeName } from '@client-portal/models/common';
import {
  ClientHttpModel,
  ClientNameChange,
  ClientNotification,
} from '@client-portal/models/client';
import { ClpEnvironment } from '@client-portal/environment';
import { ClientHttpPaths } from './client-http.paths';

@Injectable({
  providedIn: 'root',
})
export class ClientHttpService {
  public getClients$ = this._httpClient.get<Array<CodeName>>(
    `${this._env.apiEndpoint}${ClientHttpPaths.Clients}`,
  );

  constructor(
    private _httpClient: HttpClient,
    private _env: ClpEnvironment,
  ) {}

  public getClientDetail$(clientCode: string): Observable<ClientHttpModel> {
    return this._httpClient.get<ClientHttpModel>(
      `${this._env.apiEndpoint}${ClientHttpPaths.ClientsDetails.replace(
        '{clientCode}',
        clientCode,
      )}`,
    );
  }

  public getClientNotifications$(
    clientCode: string,
  ): Observable<ClientNotification> {
    return this._httpClient.get<ClientNotification>(
      `${this._env.apiEndpoint}${ClientHttpPaths.Notifications.replace(
        '{clientCode}',
        clientCode,
      )}`,
    );
  }

  public updateClientNotifications$(
    clientCode: string,
    data: Array<Operation>,
  ): Observable<HttpResponse<void>> {
    return this._httpClient.patch<void>(
      `${this._env.apiEndpoint}${ClientHttpPaths.Notifications.replace(
        '{clientCode}',
        clientCode,
      )}`,
      data,
      {
        observe: 'response',
      },
    );
  }

  public clientNameChangeRequest$(
    clientCode: string,
    data: ClientNameChange,
  ): Observable<HttpResponse<void>> {
    return this._httpClient.put<void>(
      this._env.apiEndpoint +
        ClientHttpPaths.ClientNameChange.replace('{clientCode}', clientCode),
      data,
      {
        observe: 'response',
      },
    );
  }
}
